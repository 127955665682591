export const validatePassword = (password: string) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

export const generateRandomPassword = () => {
  const length = 12;
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";

  // Ensure minimum requirements: at least one uppercase letter, one number, one lowercase letter
  let password = "";
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];

  // Add remaining random alphanumeric characters to meet the length requirement
  const allChars = uppercaseChars + numbers + lowercaseChars;
  while (password.length < length) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Fisher-Yates Shuffle
  const passwordArray = password.split("");
  for (let i = passwordArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }
  password = passwordArray.join("");

  return password;
};

export const validateWebsite = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol (http or https)
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?" + // port
      "(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // path
      "(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$" // fragment locator
  );

  return pattern.test(url);
};

export const validatePrice = (price: string) => {
  const numberValue = Number(price);
  return numberValue > 0;
};

export const validateEmail = (email: string) => {
  const pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g);

  return pattern.test(email);
};

export const validateName = (name: string) => {
  const pattern = new RegExp(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/);

  return pattern.test(name);
};

export const sortProductsByDate = (products: any) => {
  return products.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
};

export const sortProductsByName = (products: any) => {
  return products.sort((a, b) => a.title.localeCompare(b.title)); //alphabetically
};

export const sortTypesByName = (types: any) => {
  return types.sort((a, b) => a.value.localeCompare(b.value)); //alphabetically
};

export const sortProductsByType = (products: any, productTypes: any) => {
  const typeMap = new Map();

  productTypes.forEach((type) => {
    typeMap.set(type.id, type.value);
  });

  const sortedProducts = {};

  products.forEach((product) => {
    const typeName =
      product.type_id && typeMap.has(product.type_id)
        ? typeMap.get(product.type_id)
        : "Unknown";

    if (typeName != "Unknown") {
      if (!sortedProducts[typeName]) {
        sortedProducts[typeName] = [];
      }

      sortedProducts[typeName].push(product);
    }
  });

  return Object.keys(sortedProducts)
    .map((type) => ({
      type: type,
      products: sortedProducts[type],
    }))
    .sort((a, b) => a.type.localeCompare(b.type)); //sort alphabetically
};

export const formatPrice = (amount: number) => {
  if (!amount) {
    return "$0.00";
  }

  let formattedAmount = Number((amount / 100).toFixed(2));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(formattedAmount);
};

export const toTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatRetailPrice = (amount: number) => {
  if (!amount) {
    return "$0.00";
  }

  let formattedAmount = Number(amount.toFixed(2));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(formattedAmount);
};

export const sortProducts = (products: any) => {
  const order = { true: 1, null: 2, false: 3 };

  let sorted = products.sort((a, b) => {
    return (
      order[a.is_featured] - order[b.is_featured] || //sort by isfeatured
      a?.supplier?.company.localeCompare(b?.supplier?.company) || //sort by vendor name
      a.title.localeCompare(b.title) //sort by title
    );
  });

  return sorted;
};

export function truncateString(str, num) {
  if (str?.length > num) {
    return str?.slice(0, num) + " ...";
  } else {
    return str;
  }
}
export function setInitialAddressData(
  cart: any,
  saved_address: any,
  customer: any,
  billing = false
) {
  const resolveValue = (billing = false, ...args: any[]) => {
    if (billing) {
      // Prioritize saved_address when billing
      return args
        .slice(0, 2)
        .reverse()
        .find((arg) => arg !== undefined && arg !== null);
    }
    // Default behavior for shipping
    return args.find((arg) => arg !== undefined && arg !== null);
  };

  const getAddressData = (suffix: string = "") => ({
    [`firstName${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.first_name,
      saved_address?.first_name,
      customer?.first_name
    ),
    [`lastName${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.last_name,
      saved_address?.last_name,
      customer?.last_name
    ),
    [`company${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.company,
      saved_address?.company,
      customer?.company
    ),
    [`address${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.address_1,
      saved_address?.address_1,
      ""
    ),
    [`postalCode${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.postal_code,
      saved_address?.postal_code,
      ""
    ),
    [`city${suffix}`]: resolveValue(
      billing,
      cart?.shipping_address?.city,
      saved_address?.city,
      ""
    ),
    [`country${suffix}`]: resolveValue(
      billing,
      cart?.region?.countries?.find(
        (country) => country?.iso_2 == cart?.shipping_address?.country_code
      )?.display_name,
      cart?.region?.countries?.find(
        (country) => country?.iso_2 == saved_address?.country_code
      )?.display_name,
      ""
    ),
  });

  return billing ? getAddressData("Billing") : getAddressData();
}

export function getCountryCode(
  countries: any[],
  countryName: string
): string | undefined {
  return countries?.find((obj) => obj?.display_name === countryName)?.iso_2;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};
